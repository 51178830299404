<template>
  <div class="relative overflow-hidden rounded max-w-480px">
    <iframe
      width="100%"
      height="205"
      frameborder="0"
      style="max-width:1200px;"
      :class="{'mb-4': !last}"
      class="gfm-embed-iframe"
      :src="fundraiserWidget"
    >
    </iframe>

    <CloseIcon
      @click.stop="removeHandler"
      class="close-icon absolute right-0 top-0 mt-5 mr-5 sm:mt-2 sm:mr-2 lg:mt-1 lg:mr-2
       w-6 h-6 sm:w-5 sm:h-5 cursor-pointer animation-focus-default"
    ></CloseIcon>
  </div>
</template>

<script>
import CloseIcon from '@/assets/icon_close.svg';

export default {
  name: 'GoFundMeEmbed',
  components: {
    CloseIcon,
  },
  mounted() {
  },
  data() {
    return {};
  },
  methods: {
    removeHandler() {
      this.$emit('removeFundraiser');
    },
  },
  computed: {
    fundraiserWidget() {
      let urlLocal = this.url;
      let queryParams = '';
      if (urlLocal.indexOf('?') !== -1) {
        urlLocal = this.url.slice(0, urlLocal.indexOf('?'));
        queryParams = this.url.slice(this.url.indexOf('?'));
      }
      return `${urlLocal}/widget/medium${queryParams}`;
    },
  },
  props: {
    url: {
      type: String,
    },
    last: {
      type: Boolean,
    },
    removeFundraiser: {
      type: Function,
    },
  },
};
</script>

<style lang="scss">
.close-icon path {
  stroke: #C8CACD;
}
</style>
