<template>
  <div class="mx-6 sm:mx-auto mt-6">
    <div class="block sm:hidden mt-6 bg-gray-400 w-full h-[1px] opacity-40" />
    <div
      class="flex flex-wrap md:flex-nowrap mb-5 sm:my-8 gap-2 rounded px-4 py-8 bg-white mt-6 sm:mt-0"
    >
      <AdminSubNavigation
        class="w-full md:w-1/4 min-w-[260px]"
        :currentPageView="currentPageView"
        :updateCurrentPageView="updateCurrentPageView"
      />

      <div class="w-full md:w-3/4">
        <template v-if="isAdmin && currentPageView === 'admin-videos'">
          <h3 class="mb-2">Onboarding</h3>
          <iframe width="640" height="400" src="https://www.loom.com/embed/33051b45caed4044a7f6c6ff1993f898?sid=4745f49a-83d2-4da5-a663-1b8f46c8edf2&hideEmbedTopBar=true" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
          <h3 class="mb-2 mt-6">How To Create Events</h3>
          <iframe width="640" height="400" src="https://www.loom.com/embed/0e08ad6212f847afa59aa93cf2f667a0?sid=9502fbe1-0748-4fa4-a74b-a1da6cf66332&hideEmbedTopBar=true" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
        </template>
        <template v-if="isAdmin && currentPageView === 'settings'">
          <ProfileFields class="no-print" />
          <hr class="mt-8" />
          <CustomVideoPrompts class="mt-6 sm:mt-0 no-print" />
        </template>

        <template v-if="isAdmin && currentPageView === 'members'">
          <div class="mx-auto max-w-screen-xl flex flex-col-reverse xl:flex-row no-print">
            <div class="flex flex-col w-full px-0">
              <div>
                <div
                  class="flex flex-col sm:flex-row flex-wrap sm:flex-nowrap w-full justify-between items-start gap-2 sm:items-center pb-4 mb-4 border-b-gray-400 border-b border-solid"
                >
                  <h1 class="text-gray-900 font-serif text-2xl">Members</h1>
                  <Button
                    :primary-full-size="false"
                    type="secondary"
                    @onClick="openEmailModal"
                    custom-class="h-[40px] w-full sm:max-w-[220px]"
                  >
                    <template v-slot:icon>
                      <IconEmail class="icon-email" />
                    </template>
                    <span class="text-orange-400 text-center text-sm font-semibold leading-[140%]">
                      Send Group Message
                    </span>
                  </Button>
                </div>

                <div class="flex flex-wrap xl:flex-nowrap gap-2 justify-between">
                  <div
                    class="w-full order-0 flex justify-center items-center gap-1 rounded border border-gray-300 p-1 border-solid h-[46px] mb-0"
                  >
                    <button
                      class="flex justify-center items-center text-sm font-semibold min-w-[42px] max-w-[70px] p-2 sm:px-3 py-2 rounded-sm"
                      :class="
                        selectedView === 'all'
                          ? 'bg-orange-400/10 text-orange-400'
                          : 'text-gray-600'
                      "
                      type="button"
                      @click="displayUsersByStatus('all')"
                    >
                      All&nbsp;
                      <span
                        class="hidden md:inline"
                        :class="
                          selectedView === 'all'
                            ? 'bg-orange-400/10 text-orange-400'
                            : 'text-gray-600'
                        "
                      >
                        ({{ totalUsers }})
                      </span>
                    </button>
                    <button
                      class="flex justify-center items-center text-sm font-semibold min-w-[62px] max-w-[100px] p-2 sm:px-3 py-2 rounded-sm"
                      :class="
                        selectedView === 'active'
                          ? 'bg-orange-400/10 text-orange-400'
                          : 'text-gray-600'
                      "
                      type="button"
                      @click="displayUsersByStatus('active')"
                    >
                      Active&nbsp;
                      <span
                        class="hidden md:inline"
                        :class="
                          selectedView === 'active'
                            ? 'bg-orange-400/10 text-orange-400'
                            : 'text-gray-600'
                        "
                      >
                        ({{ totalActiveUsers }})
                      </span>
                    </button>
                    <button
                      class="flex justify-center items-center text-sm font-semibold min-w-[70px] max-w-[120px] p-2 sm:px-3 py-2 rounded-sm"
                      :class="
                        selectedView === 'pending'
                          ? 'bg-orange-400/10 text-orange-400'
                          : 'text-gray-600'
                      "
                      type="button"
                      @click="displayUsersByStatus('pending')"
                    >
                      Pending&nbsp;
                      <span
                        class="hidden md:inline"
                        :class="
                          selectedView === 'pending'
                            ? 'bg-orange-400/10 text-orange-400'
                            : 'text-gray-600'
                        "
                      >
                        ({{ totalPendingUsers }})
                      </span>
                    </button>
                    <button
                      class="flex justify-center items-center text-sm font-semibold min-w-[70px] max-w-[120px] p-2 sm:px-3 py-2 rounded-sm"
                      :class="
                        selectedView === 'suspended'
                          ? 'bg-orange-400/10 text-orange-400'
                          : 'text-gray-600'
                      "
                      type="button"
                      @click="displayUsersByStatus('suspended')"
                    >
                      Suspended&nbsp;
                      <span
                        class="hidden md:inline"
                        :class="
                          selectedView === 'suspended'
                            ? 'bg-orange-400/10 text-orange-400'
                            : 'text-gray-600'
                        "
                      >
                        ({{ totalSuspendedUsers }})
                      </span>
                    </button>
                  </div>

                  <TextInput
                    class="w-full sm:w-2/3 lg:w-fit order-2 sm:order-none"
                    id="search"
                    placeholder="Search members..."
                    label="Search"
                    custom-height="h-[46px]"
                    :value="searchValue"
                    @input="updateSearch"
                  >
                    <template v-slot:icon>
                      <SearchIcon />
                    </template>
                  </TextInput>
                  <Button
                    class="w-full sm:w-[150px] min-w-[150px] pl-2 pr-4 py-2 h-[46px] order-1 sm:order-none"
                    color-custom
                    :primary-full-size="false"
                    type="secondary"
                    @onClick="exportToCsv"
                  >
                    <template v-slot:icon>
                      <IconDownload />
                    </template>
                    <span class="text-sm text-gray-800 font-semibold">Export Members</span>
                  </Button>
                </div>
              </div>
              <h3
                class="text-gray-600 font-sans text-sm p-3 sm:p-2 rounded bg-blue-100/10 mb-1 mt-3"
              >
                Account statuses automatically get set to
                <strong>Active</strong> when a member completes or edits their profile unless they
                are
                <strong>Suspended</strong>
              </h3>
              <h4 v-if="loading" class="loading p-4 text-lg text-gray-900 font-bold">Loading</h4>

              <div class="rounded p-4 bg-gray-100">
                <table
                  class="user-table w-full block bg-gray-200 rounded"
                  v-if="!loading"
                  cellspacing="0"
                  cellpadding="0"
                >
                  <tr class="for-screen-reader">
                    <th>Profile Picture</th>
                    <th>Name and Location</th>
                    <th>Email</th>
                    <th>Joined Date</th>
                    <th>Active Status</th>
                  </tr>
                  <template v-for="user in filteredProfiles">
                    <AdminUserTableRow :user="user" :key="user.id" class="mb-3" />
                  </template>
                </table>
                <Pagination
                  :count="totalItems"
                  :entries="numberItemsToDisplay"
                  @changeEntries="changeNumberToDisplay"
                  @changePage="changeCurrentPage"
                  is-show-all-option
                />
              </div>
            </div>
            <div class="flex-1 hidden">
              <!-- TODO: under construction mode -->
            </div>
          </div>
        </template>
        <template v-if="isAdmin && currentPageView === 'privacy'">
          <Privacy class="no-print" />
        </template>
        <template v-if="isAdmin && currentPageView === 'subscription'">
          <div
            class="mx-auto max-w-screen-xl flex flex-col-reverse xl:flex-row no-print px-0 md:px-8"
          >
            <div class="flex flex-col">
              <div>
                <h1 class="text-gray-900 font-serif text-0_8xl md:text-2xl pb-3">Group Plan</h1>
                <div
                  class="flex flex-col sm:flex-row flex-wrap xl:flex-nowrap justify-between w-full bg-gray-100 p-3 md:p-6"
                >
                  <div class="flex flex-col mr-8 w-full xl:w-[70%]">
                    <div class="font-serif text-0_8xl md:text-2xl mb-1">
                      {{ this.group.isSubscribed ? 'Exclusive' : 'Free' }}
                    </div>
                    <div>
                      <div class="mt-3 text-sm md:text-base">
                        <template v-if="this.group && this.group.isSubscribed">
                          <p class="mb-2">
                            You're enrolled in Reunacy's
                            <span class="font-semibold">Exclusive Plan</span>. Your group is private
                            and can have unlimited members.
                          </p>
                          <p class="mb-2">Additional features of your plan include:</p>
                          <ul class="mb-4">
                            <li class="flex items-center mb-3">
                              <span class="block w-2 h-2 bg-orange-500 rounded-full mr-2" />
                              Photo upload and sharing
                            </li>
                            <li class="flex items-center mb-3">
                              <span class="block w-2 h-2 bg-orange-500 rounded-full mr-2" />
                              Message board
                            </li>
                            <li class="flex items-center mb-3">
                              <span class="block w-2 h-2 bg-orange-500 rounded-full mr-2" />
                              Ability to print your directory
                            </li>
                            <li class="flex items-center mb-3">
                              <span class="block w-2 h-2 bg-orange-500 rounded-full mr-2" />
                              Priority customer support
                            </li>
                          </ul>
                        </template>
                        <template v-else>
                          <p class="mb-2">
                            You’re using Reunacy’s <span class="font-semibold">Free Plan</span>.
                            Your group is public and may include up to 10 members.
                          </p>
                          <p class="mb-2">
                            Upgrading to our <span class="font-semibold">Exclusive Plan</span> gives
                            you:
                          </p>
                          <ul class="mb-4">
                            <li class="flex items-center mb-3">
                              <span class="block w-2 h-2 bg-orange-500 rounded-full mr-2" />
                              A private group
                            </li>
                            <li class="flex items-center mb-3">
                              <span class="block w-2 h-2 bg-orange-500 rounded-full mr-2" />
                              Unlimited members
                            </li>
                            <li class="flex items-center mb-3">
                              <span class="block w-2 h-2 bg-orange-500 rounded-full mr-2" />
                              Photo upload and sharing
                            </li>
                            <li class="flex items-center mb-3">
                              <span class="block w-2 h-2 bg-orange-500 rounded-full mr-2" />
                              Message board
                            </li>
                            <li class="flex items-center mb-3">
                              <span class="block w-2 h-2 bg-orange-500 rounded-full mr-2" />
                              7-day FREE trial
                            </li>
                          </ul>
                          <p class="mb-2">
                            All for just <span class="font-semibold">$15/month</span> – or subscribe
                            for a full year ($99) and get
                            <span class="font-semibold">5 months free.</span>
                          </p>
                        </template>
                        <p>
                          See all the features of your plan on our
                          <a
                            target="_blank"
                            href="https://reunacy.com/pricing"
                            class="text-group-color-orange underline font-semibold"
                          >
                            pricing page
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex flex-col justify-items-auto mt-2 md:mt-6 sm:mt-0 w-full xl:w-[30%] min-w-fit xl:min-w-[260px]"
                  >
                    <p
                      class="flex items-center gap-2 justify-center order-1 xl:order-0 mb-4 xl:mb-0"
                    >
                      <span
                        class="text-sm"
                        :class="
                          !isAnnualSelected
                            ? 'text-gray-900 font-semibold'
                            : 'text-gray-800 font-normal opacity-80'
                        "
                      >
                        Billed Monthly
                      </span>
                      <toggle-button
                        v-model="isAnnualSelected"
                        :width="46"
                        :height="26"
                        :color="toggleColor"
                      />
                      <span
                        class="text-sm"
                        :class="
                          isAnnualSelected
                            ? 'text-gray-900 font-semibold'
                            : 'text-gray-800 font-normal opacity-80'
                        "
                      >
                        Billed Yearly
                      </span>
                    </p>
                    <div class="order-0 xl:order-1 font-serif mx-auto my-1 md:my-4 text-center">
                      <p class="text-2_5xl md:text-5_5xl flex items-baseline">
                        ${{ this.isAnnualSelected ? '8.25' : '15' }}
                        <span class="text-gray-800 text-base"> /month </span>
                      </p>
                      <p v-if="this.isAnnualSelected" class="text-gray-800 text-base">
                        (one payment of $99)
                      </p>
                    </div>
                    <button
                      class="order-2 open-modal-btn text-lg animation-focus-default text-white border-transparent min-w-225 sm:hover:shadow-buttonPrimary mx-auto"
                      :style="{
                        'background-color': `rgba(${getButtonDefaultColor()})`,
                      }"
                      :primary-full-size="false"
                      type="primary"
                      @click="group.isSubscribed ? showPortal() : subscribe()"
                    >
                      {{ group.isSubscribed ? 'Manage Subscription' : 'Start FREE Trial' }}
                      <ArrowRight
                        v-if="!group.isSubscribed"
                        class="w-3 ml-2 mt-0.5 text-white"
                        style="color: white"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="isAdmin && currentPageView === 'group-settings'">
          <div class="mx-auto max-w-screen-xl flex flex-col no-print px-0 md:px-8">
            <div class="flex flex-col">
              <h1 class="text-gray-900 font-serif text-0_8xl md:text-2xl">Group Settings</h1>
              <p class="text-gray-700 mt-1 mb-6 text-sm">
                Select the instances you want to see in navigation
              </p>
            </div>
            <div
              class="flex justify-between items-start gap-2 md:gap-8 self-stretch mb-2 pl-4 pr-6 py-3 rounded-md bg-gray-100"
            >
              <div>
                <p class="mb-1.5 text-gray-900 text-sm md:text-base font-semibold leading-[140%]">
                  Photos
                </p>
                <p class="self-stretch text-gray-900 text-[13px] md:text-sm leading-[140%]">
                  Allows you to upload and store photos in our system
                </p>
              </div>
              <toggle-button
                :value="isPhotoEnabled"
                @input="updatePhotoSettings"
                :width="46"
                :height="26"
                :color="toggleColor"
                :disabled="!group.isSubscribed"
              />
            </div>

            <div class="pl-4 mb-2 pr-6 py-3 rounded-md bg-gray-100">
              <div class="flex justify-between items-start gap-2 md:gap-8 self-stretch">
                <div>
                  <p class="mb-1.5 text-gray-900 text-sm md:text-base font-semibold leading-[140%]">
                    Message Board
                  </p>
                  <p class="self-stretch text-gray-900 text-[13px] md:text-sm leading-[140%]">
                    Allows you to share news, photos with other group members, see notifications
                    about what is happening in the group and much more.
                  </p>
                </div>
                <toggle-button
                  :value="isMessageBoardEnabled"
                  @input="updateMessageBoardSettings"
                  :width="46"
                  :height="26"
                  :color="toggleColor"
                  :disabled="!group.isSubscribed"
                />
              </div>

              <div>
                <div class="text-gray-900 text-sm mt-4 pt-4 border-t border-gray-900/10">
                  <p class="leading-[140%] mb-2">Additional Features</p>
                  <div class="flex justify-between items-start gap-2 md:gap-8 self-stretch">
                    <div>
                      <p
                        class="mb-1.5 text-gray-900 text-sm md:text-base font-semibold leading-[140%]"
                      >
                        Flag Offensive Content
                      </p>
                      <p class="self-stretch text-gray-900 text-[13px] md:text-sm leading-[140%]">
                        Provides option for members to flag posts as offensive which is reported to Group Admins
                      </p>
                    </div>

                    <toggle-button
                      :value="isOffensiveContentFlaggingEnabled"
                      @input="updateOffensiveContentFlagging"
                      :width="46"
                      :height="26"
                      :color="toggleColor"
                      :disabled="!group.isSubscribed"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="flex justify-between items-start gap-2 md:gap-8 self-stretch mb-2 pl-4 pr-6 py-3 rounded-md bg-gray-100"
            >
              <div>
                <p class="mb-1.5 text-gray-900 text-sm md:text-base font-semibold leading-[140%]">
                  Fun Facts
                </p>
                <p class="self-stretch text-gray-900 text-[13px] md:text-sm leading-[140%]">
                  Learn interesting facts about the group and what members have in common
                </p>
              </div>
              <toggle-button
                :value="isAIIntegrationEnabled"
                @input="updateAIIntegrationFlagging"
                :width="46"
                :height="26"
                :color="toggleColor"
                :disabled="!group.isSubscribed"
              />
            </div>

            <div
              class="flex justify-between items-start gap-2 md:gap-8 self-stretch mb-2 pl-4 pr-6 py-3 rounded-md bg-gray-100"
            >
              <div>
                <p class="mb-1.5 text-gray-900 text-sm md:text-base font-semibold leading-[140%]">
                  Member Notifications
                </p>
                <p class="self-stretch text-gray-900 text-[13px] md:text-sm leading-[140%]">
                  Control whether members receive notification emails from the group
                </p>
              </div>
              <toggle-button
                :value="isMemberNotificationsEnabled"
                @input="updateMemberNotificationsFlagging"
                :width="46"
                :height="26"
                :color="toggleColor"
              />
            </div>
            <div
              class="flex justify-between items-start gap-2 md:gap-8 self-stretch mb-2 pl-4 pr-6 py-3 rounded-md bg-gray-100"
            >
              <div>
                <p class="mb-1.5 text-gray-900 text-sm md:text-base font-semibold leading-[140%]">
                  Created by Admin
                </p>
                <p class="self-stretch text-gray-900 text-[13px] md:text-sm leading-[140%]">
                  Choose to display the “Created by Admin” icon
                </p>
              </div>
              <toggle-button
                :value="isCreatedByAdminLabelEnabled"
                @input="updateCreatedByAdminLabelFlagging"
                :width="46"
                :height="26"
                :color="toggleColor"
              />
            </div>
            <button
              @click="handleDeleteClick"
              type="button"
              class="delete-button flex items-center justify-center text-sm rounded transition"
            >
              <span class="rounded-[40px]">
                <DeleteIcon class="delete-icon w-4 h-4 lg:mr-3 -mt-1 lg:mt-0" />
              </span>
              Delete Group
            </button>
          </div>
        </template>
      </div>
    </div>
    <template v-if="showEmailModal">
      <transition name="component-fade" mode="out-in">
        <AdminEmailModal :hideModal="closeEmailModal" />
      </transition>
    </template>
    <transition-group name="component-fade" mode="out-in">
      <DialogDefault
        v-if="showDeactivateDialog"
        header="Delete Group?"
        applyBtnText="Delete"
        scheme="secondary"
        container-class="sm:w-[512px]"
        @cancel="showDeactivateDialog = false"
        @apply="handleDeactivate()"
        key="deactivate-modal"
      >
        <p class="pb-2 pt-4">
          If you delete this group any content you’ve created or shared (messages, photos, events, etc.)
          will no longer be accessible, and any Members of the group will also lose access.
        </p>
        <p class="pb-2">
          Note that
          <span class="font-bold">
            you have the option to make another member the Admin of the group
            and then you can leave the group.
          </span>
        </p>
        <p class="pb-6">Are you still sure you want to delete the group?</p>
      </DialogDefault>
      <DialogDefault
        v-if="showDeactivateInfoDialog"
        header="Information"
        text="Your group has an active subscription attached to it. Please go to the “Subscription” section and unsubscribe, then you can delete the group."
        info-btn-text="OK"
        :isShowControlButtons="false"
        show-info-button
        is-show-close-btn
        scheme="secondary"
        container-class="sm:w-[512px]"
        @cancel="showDeactivateInfoDialog = false"
        @apply="handleDeactivateInfoModalSubmit()"
        key="deactivate-info-modal"
      />
    </transition-group>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Button from '@/components/Button.vue';
import TextInput from '@/components/TextInput.vue';
import AdminEmailModal from '@/components/AdminEmailModal.vue';
import AdminUserTableRow from '@/components/AdminUserTableRow.vue';
import AdminSubNavigation from '@/components/AdminSubNavigation.vue';
import CustomVideoPrompts from '@/components/CustomVideoPrompts.vue';
import DialogDefault from '@/components/DialogDefault.vue';
import Privacy from '@/views/Admin/Privacy.vue';
import ProfileFields from '@/components/ProfileFields.vue';
import SearchIcon from '@/assets/search.svg';
import { DEFAULT_INTERACTIVE_COLOR } from '@/components/constants/groupColors';
import ArrowRight from '@/assets/arrow_right_white.svg';
import IconEmail from '@/assets/email.svg';
import DeleteIcon from '@/assets/delete.svg';
import IconDownload from '@/components/svgComponents/IconDownload.vue';
import Pagination from '@/components/ui/Pagination.vue';
import { constants } from '../../config';
import api from '../../store/api';

const TOKEN = localStorage.getItem('access-token');

export default {
  name: 'Admin',
  components: {
    DeleteIcon,
    DialogDefault,
    IconDownload,
    IconEmail,
    Pagination,
    CustomVideoPrompts,
    Button,
    AdminEmailModal,
    TextInput,
    AdminUserTableRow,
    SearchIcon,
    AdminSubNavigation,
    Privacy,
    ProfileFields,
    ArrowRight,
  },
  props: {
    currentPageViewProp: {
      type: String,
      required: false,
      default: () => 'subscription',
    },
  },
  data() {
    return {
      currentPageView: this.currentPageViewProp,
      loading: false,
      selectedView: 'all',
      searchValue: '',
      showEmailModal: false,
      currentGroup: {},
      groupProfiles: [],
      filteredProfiles: [],
      searchDebounce: '',
      defaultButtonColor: DEFAULT_INTERACTIVE_COLOR,
      cbInstance: null,
      portal: null,
      isAnnualSelected: true,
      totalItems: 0,
      numberItemsToDisplay: '10',
      currentPage: 1,
      showDeactivateDialog: false,
      showDeactivateInfoDialog: false,
    };
  },
  computed: {
    ...mapGetters(['group', 'profiles', 'user', 'settingsGroupFeatureFlags']),

    isPhotoEnabled() {
      return this.settingsGroupFeatureFlags.isGroupPhotosEnabled;
    },
    isMessageBoardEnabled() {
      return this.settingsGroupFeatureFlags.isMessageBoardEnabled;
    },
    isAIIntegrationEnabled() {
      return this.settingsGroupFeatureFlags.isAiIntegrationEnabled;
    },
    isMemberNotificationsEnabled() {
      return this.settingsGroupFeatureFlags.isMemberNotificationsEnabled;
    },
    isCreatedByAdminLabelEnabled() {
      return this.settingsGroupFeatureFlags.isCreatedByAdminLabelEnabled;
    },
    isOffensiveContentFlaggingEnabled() {
      return this.settingsGroupFeatureFlags.isOffensiveContentFlaggingEnabled;
    },
    totalUsers() {
      return this.groupProfiles.length;
    },
    totalActiveUsers() {
      return this.groupProfiles.slice().filter((user) => user.status === 'active').length;
    },
    totalPendingUsers() {
      return this.groupProfiles.slice().filter((user) => user.status === 'pending').length;
    },
    totalSuspendedUsers() {
      return this.groupProfiles.slice().filter((user) => user.status === 'suspended').length;
    },
    isAdmin() {
      if (this.$route.params.group && this.user.id) {
        return (
          this.user.adminOfGroups.indexOf(this.$route.params.group) >= 0 ||
          this.user.user.isSuperuser
        );
      }
      return false;
    },
    toggleColor() {
      return {
        checked: `rgba(${this.getButtonDefaultColor()})`,
        unchecked: '#AAACAF',
        disabled: '#CCCCCC',
      };
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.cbInstance = Chargebee.init({
      site: constants.CHARGEBEE_SOURCE,
      isItemsModel: true,
    });
    this.cbInstance.isItemsModel = true;
    this.cart = this.cbInstance.getCart();
    this.portal = this.cbInstance.createChargebeePortal();
  },
  watch: {
    async numberItemsToDisplay(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.fetchData();
      }
    },
    async currentPage(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.fetchData();
      }
    },
  },
  created() {
    this.loading = true;
    if (TOKEN) {
      this.fetchCurrentUser().then(() => {
        if (
          this.user.adminOfGroups.indexOf(this.$route.params.group) < 0 &&
          !this.user.user.isSuperuser
        ) {
          // redirect if the user doesn't exist
          this.$router.replace({
            name: 'members',
            params: {
              group: this.$route.params.group,
            },
          });
        }
        this.fetchData();
      });

      this.fetchSettingFeatureFlags(this.group.id);
    } else {
      this.$router.replace({ name: 'login' });
    }
  },
  methods: {
    ...mapActions([
      'updateGroup',
      'fetchCurrentUser',
      'updateSettingFeatureFlags',
      'fetchSettingFeatureFlags',
      'getSubscriptionStatus',
    ]),

    handleDeactivate() {
      this.updateGroup({
        groupSlug: this.group.slug,
        group: { is_deactivated: true },
      }).then((response) => {
        if (response.success) {
          this.fetchCurrentUser();
          this.$router.push({ name: 'groups' });
        } else if (response.errors) {
          console.error(response.errors);
        }
        this.showDeactivateDialog = false;
      });
    },

    async handleDeleteClick() {
      const isActiveSubscription = this.group.isSubscribed
        ? await this.getSubscriptionStatus({ groupSlug: this.group.slug })
        : false;

      if (isActiveSubscription) {
        this.showDeactivateInfoDialog = true;
      } else {
        this.showDeactivateDialog = true;
      }
    },

    handleDeactivateInfoModalSubmit() {
      this.showDeactivateInfoDialog = false;
      this.updateCurrentPageView('subscription');
    },

    async updateSettings(settings) {
      const payload = {
        groupId: this.group.id,
        groupSlug: this.group.slug,
        isMessageBoardEnabled:
          settings.isMessageBoardEnabled !== undefined
            ? settings.isMessageBoardEnabled
            : this.isMessageBoardEnabled,
        isAiIntegrationEnabled:
          settings.isAIIntegrationEnabled !== undefined
            ? settings.isAIIntegrationEnabled
            : this.isAIIntegrationEnabled,
        isMemberNotificationsEnabled:
          settings.isMemberNotificationsEnabled !== undefined
            ? settings.isMemberNotificationsEnabled
            : this.isMemberNotificationsEnabled,
        isCreatedByAdminLabelEnabled:
          settings.isCreatedByAdminLabelEnabled !== undefined
            ? settings.isCreatedByAdminLabelEnabled
            : this.isCreatedByAdminLabelEnabled,
        isOffensiveContentFlaggingEnabled:
          settings.isOffensiveContentFlaggingEnabled !== undefined
            ? settings.isOffensiveContentFlaggingEnabled
            : this.isOffensiveContentFlaggingEnabled,
        isGroupPhotosEnabled:
          settings.isPhotoEnabled !== undefined ? settings.isPhotoEnabled : this.isPhotoEnabled,
      };
      await this.updateSettingFeatureFlags(payload);
    },

    async updateMessageBoardSettings(value) {
      await this.updateSettings({ isMessageBoardEnabled: value });
    },

    async updatePhotoSettings(value) {
      await this.updateSettings({ isPhotoEnabled: value });
    },

    async updateOffensiveContentFlagging(value) {
      await this.updateSettings({ isOffensiveContentFlaggingEnabled: value });
    },

    async updateAIIntegrationFlagging(value) {
      await this.updateSettings({ isAIIntegrationEnabled: value });
    },
    async updateMemberNotificationsFlagging(value) {
      await this.updateSettings({ isMemberNotificationsEnabled: value });
    },
    async updateCreatedByAdminLabelFlagging(value) {
      await this.updateSettings({ isCreatedByAdminLabelEnabled: value });
    },

    async fetchData() {
      try {
        const response = await api.get(
          `api/v1/groups/${this.$route.params.group}/profiles/?page_size=${this.numberItemsToDisplay}&page=${this.currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
            },
          },
        );

        this.loading = false;
        this.groupProfiles = response.data.results;
        this.filteredProfiles = response.data.results;
        this.totalItems = response.data.count;
      } catch (e) {
        console.log(e);
      }
    },

    openEmailModal() {
      this.showEmailModal = true;
    },

    closeEmailModal() {
      this.showEmailModal = false;
    },

    changeNumberToDisplay(value) {
      this.numberItemsToDisplay = value;
    },

    changeCurrentPage(value) {
      this.currentPage = value;
    },

    updateCurrentPageView(view) {
      this.currentPageView = view;
    },
    exportToCsv() {
      const filteredProfileFieldsHeader = this.addCsvHeader();
      const filteredProfileFields = this.filteredProfiles.map((filteredProfileObject) => {
        return {
          firstName: filteredProfileObject.firstName,
          lastName: filteredProfileObject.lastName,
          previousFirstName: filteredProfileObject.previousFirstName || '',
          previousLastName: filteredProfileObject.previousLastName || '',
          city: filteredProfileObject.city || '',
          state: filteredProfileObject.state || '',
          zip: filteredProfileObject.zip || '',
          country: filteredProfileObject.country || '',
          streetAddress: filteredProfileObject.streetAddress
            ? `"${filteredProfileObject.streetAddress}"`
            : '',
          email: filteredProfileObject.email || '',
          link: filteredProfileObject.link || '',
          bio: filteredProfileObject.bio
            ? `"${filteredProfileObject.bio.replace(/(<([^>]+)>)/gi, '')}"`
            : '', // remove html
          employer: filteredProfileObject.employer ? `"${filteredProfileObject.employer}"` : '',
          major: filteredProfileObject.major ? `"${filteredProfileObject.major}"` : '',
          school: filteredProfileObject.school ? `"${filteredProfileObject.school}"` : '',
          joinedDate: filteredProfileObject.joinedDate,
          status: filteredProfileObject.status,
          isGroupAdmin: filteredProfileObject.isGroupAdmin ? 'Yes' : 'No',
        };
      });
      const finalArray = filteredProfileFieldsHeader.concat(filteredProfileFields);
      const jsonObject = JSON.stringify(finalArray);
      const csvContent = `data:text/csv;charset=utf-8,${this.convertToCSV(jsonObject)}`;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `${this.group.name}_users.csv`);
      document.body.appendChild(link);
      link.click();
    },
    updateSearch(value) {
      this.searchValue = value;
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(() => {
        this.filterProfiles();
      }, 400);
    },
    filterProfiles() {
      if (!this.profiles.length) return;
      this.filteredProfiles = this.groupProfiles.filter((profile) => {
        const fullName = `${profile.firstName.toLowerCase()} ${profile.lastName.toLowerCase()}`;
        return (
          profile.firstName.toLowerCase().indexOf(this.searchValue.toLowerCase()) >= 0 ||
          profile.lastName.toLowerCase().indexOf(this.searchValue.toLowerCase()) >= 0 ||
          fullName.indexOf(this.searchValue.toLowerCase()) >= 0 ||
          profile.email.toLowerCase().indexOf(this.searchValue.toLowerCase()) >= 0
        );
      });
    },
    displayUsersByStatus(status) {
      this.selectedView = status;
      this.filteredProfiles =
        status === 'all'
          ? this.groupProfiles
          : this.groupProfiles.slice().filter((user) => user.status === status);
    },
    convertToCSV(objArray) {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      let str = '';

      for (let i = 0; i < array.length; i += 1) {
        let line = '';
        for (const index in array[i]) {
          if (line !== '') line += ',';
          line += array[i][index];
        }
        str += `${line}\r\n`;
      }
      return str;
    },
    addCsvHeader() {
      return [
        {
          firstName: 'First Name',
          lastName: 'Last Name',
          previousFirstName: 'Previous First Name',
          previousLastName: 'Previous Last Name',
          city: 'City',
          state: 'State',
          zip: 'Zip',
          country: 'Country',
          streetAddress: 'Street Address',
          email: 'Email',
          link: 'link',
          bio: 'Bio',
          employer: 'Employer',
          major: 'Major',
          school: 'School',
          joinedDate: 'Date Joined',
          status: 'Status',
          isGroupAdmin: 'Group Admin',
        },
      ];
    },
    isColorDefault() {
      return this.group.primaryColor === this.defaultColor;
    },
    getButtonDefaultColor() {
      return this.defaultButtonColor;
    },
    showPortal() {
      this.portal.open();
    },
    subscribe() {
      const planPriceId = this.isAnnualSelected
        ? constants.CHARGEBEE_YEARLY
        : constants.CHARGEBEE_MONTHLY; // Plan price point ID is used to identify the product
      const planPriceQuantity = 1;
      const product = this.cbInstance.initializeProduct(planPriceId, planPriceQuantity);
      product.isItemsModel = true;
      this.cart.replaceProduct(product);
      const customer = {
        first_name: this.user.firstName,
        last_name: this.user.lastName,
        email: this.user.email,
        billing_address: {
          first_name: this.user.firstName,
          last_name: this.user.lastName,
        },
      };

      this.cart.setCustomer(customer);

      // Passing values for custom fields
      product.setCustomData({
        cf_group_slug: this.group.slug,
        cf_group_name: this.group.name,
      });

      // Opening the checkout
      this.cart.proceedToCheckout();
    },
  },
};
</script>
<style lang="scss" scoped>
.user-table {
  border-spacing: 0;
  border-collapse: separate;
  border: none;
}

.user-table tr {
  border: none;
}

.user-table td {
  border: none;
}

.flex-3 {
  flex: 3;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}

.pending-btn {
  margin-right: 0 !important;
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}

.for-screen-reader {
  @apply absolute overflow-hidden;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.v-switch-label {
  font-weight: 200 !important;
}

.icon-email {
  path,
  rect {
    @apply stroke-orange;
  }
}

.delete-icon path {
  @apply stroke-red;
}

.delete-button {
  background: rgba(253, 33, 18, 0.08);
  color: red;
  height: 40px;
  width: 180px;
  margin-top: 1rem;

  &:hover {
    background: rgba(253, 33, 18, 0.2);
  }
}
</style>
