<template>
  <div v-if="!notAuthenticatedOrNotMember">
    <div
      class="py-8 sm:py-12 mx-6 sm:mx-auto max-w-screen-xl border-t border-gray-300 sm:border-none no-print"
    >
      <div
        v-if="showFutureEvents(groupEvents) || (group.fundraisers && group.fundraisers.length)"
        class="flex flex-col sm:flex-row pb-8 sm:pb-12 border-b border-gray-300 no-print"
      >
        <div
          class="list-container w-full sm:w-1/2 sm:pr-3 lg:pr-4"
          v-if="showFutureEvents(groupEvents)"
        >
          <div class="preview-header-container sm:h-40px">
            <h3>Events</h3>
            <button
              type="button"
              @click="seeAllEvents()"
              :hidden="!showFutureEvents(groupEvents)"
              class="see-all-btn animation-focus-default"
            >
              See All
            </button>
          </div>

          <EventList class="flex-1" :showPastEvents="false" :isPreview="isPreviewEvents">
          </EventList>
        </div>

        <div
          class="list-container w-full sm:w-1/2 sm:pl-3 lg:pl-4 flex-1 mt-8 sm:mt-0"
          v-if="group.fundraisers && group.fundraisers.length"
        >
          <div class="preview-header-container sm:h-40px">
            <h3>Fundraisers</h3>
            <button
              type="button"
              :hidden="!(group.fundraisers && group.fundraisers.length)"
              @click="seeAllFundraisers()"
              class="see-all-btn animation-focus-default"
            >
              See All
            </button>
          </div>

          <div v-for="(fundraiser, index) in group.fundraisers" :key="fundraiser.id">
            <GoFundMeEmbed
              :last="index === group.fundraisers.length - 1"
              :url="fundraiser.url"
              @removeFundraiser="deleteFundraiserDialog(index)"
            />
          </div>
        </div>

        <!--                          -->
        <!-- DIALOG DELETE FUNDRAISER -->
        <!--                          -->

        <transition name="component-fade" mode="out-in">
          <DialogDefault
            v-if="showRemoveFundraiserDialog"
            :icon="'delete'"
            :header="getFundraiserDialogName()"
            :text="getFundraiserDialogText()"
            :applyBtnText="'Delete'"
            @cancel="cancelRemovingFundraiser()"
            @apply="removeFundraiser()"
          ></DialogDefault>
        </transition>
      </div>

      <div class="flex flex-col sm:flex-row items-start sm:items-center justify-between no-print">
        <div
          :class="{
            'pt-8':
              showFutureEvents(groupEvents) || (group.fundraisers && group.fundraisers.length),
          }"
          class="flex flex-col sm:flex-row sm:justify-between items-start sm:items-center sm:bg-transparent pb-4 sm:pb-8 rounded w-full"
        >
          <h3>{{ profiles.length }} {{ 'Member' | pluralize(profiles.length) }}</h3>

          <div class="flex justify-end flex-grow">
            <div class="sm:mr-2">
              <Button
                v-tooltip="'Print Directory'"
                v-if="isAdmin && group.isSubscribed"
                color-custom
                type="primary"
                @onClick="printDiv()"
              >
                <PrintIcon class="w-5" />
              </Button>
            </div>
          </div>
          <div class="mt-5 sm:mt-0 w-full sm:w-470px block relative">
            <TextInput
              id="search"
              placeholder="Search"
              label="Search"
              :is-search="true"
              :search-include-my-list="true"
              :value="searchValue"
              :has-clear-icon="true"
              custom-height="h-[46px]"
              @focused="searchFocused"
              @input="updateSearch"
              @onKeyEnter="searchEnterHit(true)"
            >
              <template v-slot:icon>
                <SearchIcon class="w-4" />
              </template>
            </TextInput>

            <div class="checkbox-filter">
              <Checkbox
                id="filter_on_list2"
                label="My List"
                label-icon="bookmark"
                v-model="filterOnList"
                :primary-color="true"
                @change="handleOnListFilter"
              />
            </div>
            <div
              v-if="filteredProfiles.length && searchValue.length && showFilterResults"
              class="search-dropdown-container"
            >
              <div class="search-dropdown custom-scroll">
                <div
                  :key="index"
                  v-for="(profile, index) in filteredProfiles"
                  :class="{ 'mb-4': index !== filteredProfiles.length - 1 }"
                  class="search-profile-item"
                >
                  <router-link
                    class="flex items-center cursor-pointer"
                    :to="{
                      name: 'profile',
                      params: {
                        group: group.slug,
                        user: profile.slug,
                      },
                    }"
                  >
                    <img
                      class="rounded-full w-9 h-9 mr-4"
                      :src="profile.profilePicture"
                      alt="profile.profile_picture"
                    />
                    <div
                      v-html="
                        $options.filters.highlight(
                          profile.firstName + ' ' + profile.lastName,
                          searchValue
                        )
                      "
                      class="text-sm text-gray-500"
                    >
                      {{ profile.firstName }} {{ profile.lastName }}
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template v-if="welcomeModalOpen">
        <WelcomeModal :hideModal="hideWelcomeModal" />
      </template>

      <div
        v-if="!group.isSubscribed && isShowSubscribeBanner"
        class="flex w-full rounded px-2 md:px-6 py-3 md:py-4 bg-group-color-orangeLight mb-4 relative"
      >
        <div
          class="flex w-full flex-col lg:flex-row flex-wrap lg:flex-nowrap justify-between items-start lg:items-center"
        >
          <div class="mb-2 lg:mb-0 pr-6 md:pr-0 w-full lg:w-[60%]">
            <p
              class="text-gray-900 text-base md:text-xl mb-2 md:mb-0 font-bold leading-[130%] tracking-[-1px]"
            >
              You’ve used {{ profiles.length }} out of 10 free member invitations
            </p>
            <p class="text-gray-800 text-xs md:text-sm font-semibold leading-[140%]">
              Get an unlimited number of members in your group with an exclusive subscription plan.
            </p>
          </div>
          <div
            class="flex items-start lg:items-center justify-end flex-col lg:flex-row flex-wrap lg:flex-nowrap w-full lg:w-[40%]"
          >
            <p
              class="text-orange-400 text-center text-sm font-semibold leading-[140%] mr-0 lg:mr-4 mb-2 lg:mb-0"
            >Upgrade to an Exclusive Plan
              for only $15 per month
            </p>
            <Button
              class="w-full sm:max-w-[230px] whitespace-nowrap"
              type="primary"
              @onClick="subscribe"
            >
              Start FREE Trial
            </Button>
            <button
              class="absolute top-2 right-2 lg:static font-bold animation-focus-default ml-3"
              type="text"
              @click="closeSubscribe"
            >
              <IconClose class="block w-6 h-6 member__icon-close" />
            </button>
          </div>
        </div>

        <transition name="component-fade" mode="out-in">
          <DialogDefault
            v-if="isShowAdminUpgradeModal"
            header="Ask Admin to Upgrade"
            text="The email will be sent to group admins who have agreed to receive messages."
            @cancel="hideAdminUpgradeModal"
            @apply="requestToAdmin"
            is-show-close-btn
            scheme="secondary"
            apply-btn-text="Send Message"
            container-class="sm:w-[800px]"
            action-btn-class="w-full md:w-[220px] text-sm"
            action-wrapper-class="justify-between"
          >
            <form class="pb-12">
              <input
                type="text"
                v-model="adminMessage"
                placeholder="Enter message"
                class="input h-[56px] py-4 px-5 w-full text-gray-900 bg-white rounded shadow border border-gray-300 text-base placeholder-gray-500 focus:outline-none transition-colors duration-200 ease"
              />
            </form>
          </DialogDefault>
        </transition>
      </div>

      <div class="no-print">
        <div class="flex flex-wrap sm:min-h-364px" :class="{ 'min-h-170px': !isLoading }">
          <div class="flex w-full justify-center" v-if="!isLoading && !profiles.length">
            <div v-if="filterOnList" class="flex justify-center my-4 p-4">
              <div class="w-full sm:w-2/3 md:w-3/5 flex flex-col justify-center items-center">
                <ListInactive class="inline-block mb-6" />
                <h1 class="font-serif font-bold text-xl sm:text-3xl text-gray-900 mb-4 text-center">
                  Add profiles to your list
                </h1>
                <p class="text-gray-600 text-center mb-4">
                  Adding profiles to your list helps you find, remember, and connect with people in
                  your group.
                </p>
              </div>
            </div>
            <div v-else-if="filterAttending" class="flex justify-center my-4 p-8">
              <div class="w-full sm:w-2/3 md:w-3/5 flex flex-col justify-center items-center">
                <h1 class="font-serif font-bold text-xl sm:text-3xl text-gray-900 mb-4 text-center">
                  No profiles found
                </h1>
                <p class="text-gray-600 text-center mb-4">
                  We didn't find anyone in your group who said that they're attending the next
                  event.
                </p>
              </div>
            </div>
          </div>

          <div
            class="hidden sm:flex justify-between flex-nowrap overflow-hidden w-full md:w-720px xl:w-970px 2xl:w-full"
            v-if="!profiles.length"
          >
            <LoadingProfileCard class="mx-auto" :key="emptyCard" v-for="emptyCard in Array(5)" />
          </div>

          <div v-if="!profiles.length" class="flex sm:hidden w-full h-150px">
            <LoadingProfileCard />
          </div>

          <div
            v-if="profiles.length"
            class="grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 sm:gap-7 lg:gap-5 2xl:gap-4 3xl:gap-9"
          >
            <ProfileCard
              v-for="profile in cardProfilesShown"
              :key="profile.id"
              :profile="profile"
              :groupSlug="group.slug"
              :visibleFields="visibleCustomFieldSlugs"
              :showSchool="group.schoolShowYearbook"
              :showEmployer="group.employerShowYearbook"
              :showMajor="group.majorShowYearbook"
              showDataOverflow
              @click.native="profileLinkClicked"
              @setOnYourList="setOnYourList(profile)"
              :isShowAdminLabel="groupFeatureFlags && groupFeatureFlags.createdByAdminLabelEnabled"
            />

            <div
              v-if="showInviteMembersPrompt"
              class="member-card flex-col items-center justify-center p-7 sm:p-6 cursor-default"
            >
              <div
                @click="showModal"
                class="circle animation-focus-default cursor-pointer hidden sm:flex"
              >
                <AddUserIcon class="w-8 -mr-1" />
              </div>
              <h5 class="font-serif sm:font-sans text-center w-3/4 sm:w-full mb-4 sm:my-6">
                Have a friend who should join?
              </h5>
              <Button color-custom type="primary" @onClick="showModal"> Invite them! </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="printDirectory" class="only-print" v-if="isAdmin && this.group.isSubscribed">
      <PrintView />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import Button from '@/components/Button.vue';
import Checkbox from '@/components/Checkbox.vue';
import ListInactive from '@/assets/list-icon-static.svg';
import ProfileCard from '@/components/ProfileCard.vue';
import SearchIcon from '@/assets/icon_search.svg';
import AddUserIcon from '@/assets/icon_add_user.svg';
import TextInput from '@/components/TextInput.vue';
import WelcomeModal from '@/components/WelcomeModal.vue';
import EventList from '@/components/events/EventList.vue';
import GoFundMeEmbed from '@/components/fundraisers/GoFundMeEmbed.vue';
import LoadingProfileCard from '@/components/LoadingProfileCard.vue';
import DialogDefault from '@/components/DialogDefault.vue';
import { requestSubscriptionService } from '@/services/global.service';
import PrintIcon from '@/assets/print-solid.svg';
import IconClose from '@/components/svgComponents/IconClose.vue';
import PrintView from './PrintView.vue';

const SMALL_GROUP_COUNT = 15;

export default {
  name: 'Members',
  components: {
    IconClose,
    LoadingProfileCard,
    Button,
    Checkbox,
    ListInactive,
    ProfileCard,
    SearchIcon,
    AddUserIcon,
    TextInput,
    WelcomeModal,
    EventList,
    GoFundMeEmbed,
    DialogDefault,
    PrintIcon,
    PrintView,
  },
  data() {
    return {
      adminMessage: 'Please consider upgrading our group.',
      filterAttending: false,
      filterOnList: false,
      filteredProfiles: [],
      searchDebounce: '',
      searchValue: '',
      SMALL_GROUP_COUNT,
      profileInfo: {
        groupSlug: this.$route.params.group,
        sort: '+last_name',
        filters: {},
      },
      isPreviewEvents: true,
      isSearchFocused: false,
      isShowAdminUpgradeModal: false,
      showRemoveFundraiserDialog: false,
      currentFundraiser: {},
      profilesEnterHit: false,
      isShowSubscribeBanner: false,
      showFilterResults: true,
    };
  },
  computed: {
    ...mapGetters([
      'currentProfile',
      'group',
      'isAuthenticated',
      'isLoading',
      'profileFields',
      'profiles',
      'user',
      'welcomeModalOpen',
      'groupEvents',
      'groupFeatureFlags',
    ]),
    token() {
      return localStorage.getItem('access-token');
    },
    isAdmin() {
      const currentUser = this.profiles.slice().filter((member) => member.id === this.user.id);
      const isAdmin = currentUser.length ? currentUser[0].isGroupAdmin : false;
      return isAdmin || this.isSuperuser;
    },
    memberOfGroup() {
      return (
        this.user &&
        this.user.groups &&
        this.user.groups.some((group) => group.slug === this.$route.params.group)
      );
    },
    isSuperuser() {
      return !!this.user && !!this.user.user && !!this.user.user.isSuperuser;
    },
    notAuthenticatedOrNotMember() {
      if (this.isSuperuser) return false;
      return !this.isAuthenticated || (this.isAuthenticated && !this.memberOfGroup);
    },
    showInviteMembersPrompt() {
      return (
        this.profiles &&
        this.profiles.length < this.SMALL_GROUP_COUNT &&
        this.memberOfGroup &&
        (this.isAdmin || (!this.isAdmin && this.group.isolateMembers === false))
      );
    },
    visibleCustomFieldSlugs() {
      return this.profileFields.filter((field) => field.showOnYearbook).map((field) => field.slug);
    },

    cardProfilesShown() {
      if (this.profilesEnterHit) {
        return this.filteredProfiles;
      }
      return this.profiles;
    },
  },

  created() {
    this.checkAuthentication();
    if (this.notAuthenticatedOrNotMember) {
      this.$router.push({
        name: 'preview',
        params: {
          group: this.$route.params.group,
        },
      });
    } else {
      this.fetchProfiles(this.profileInfo);

      const bannerToken = localStorage.getItem('subscribe-banner');
      this.isShowSubscribeBanner = bannerToken !== this.token;
      this.fetchEventList({ groupSlug: this.$route.params.group });
    }
  },

  methods: {
    ...mapActions([
      'createGroupVideoPrompt',
      'fetchProfile',
      'fetchProfileFields',
      'fetchProfiles',
      'updateModalOpen',
      'updateWelcomeModalOpen',
      'fetchEventList',
      'addProfileToList',
      'removeProfileFromList',
      'updateGroup',
    ]),
    closeSubscribe() {
      localStorage.setItem('subscribe-banner', this.token);
      this.isShowSubscribeBanner = false;
    },
    showAdminUpgradeModal() {
      this.isShowAdminUpgradeModal = true;
    },

    hideAdminUpgradeModal() {
      this.isShowAdminUpgradeModal = false;
    },
    async requestToAdmin() {
      await requestSubscriptionService(this.group.slug, this.adminMessage, 'members');
      this.hideAdminUpgradeModal();
    },
    subscribe() {
      if (this.isAdmin) {
        this.$router.push({
          name: 'admin',
          params: {
            group: this.group.slug,
            currentPageViewProp: 'subscription',
          },
        });
      } else {
        this.showAdminUpgradeModal();
        this.closeSubscribe();
      }
    },
    addDefaultVideoPrompts() {
      this.promptOptions.forEach((prompt) => {
        this.createGroupVideoPrompt({
          groupSlug: this.$route.params.group,
          prompt,
        });
      });
    },
    showFutureEvents(events) {
      const futureEvents = events.filter((x) => {
        return DateTime.fromISO(x.startDatetime) > DateTime.now().minus({ days: 1 });
      });

      return !!(futureEvents && futureEvents.length);
    },
    updateSearch(search) {
      this.searchValue = search;
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(() => {
        // Google Analytics Event
        this.$gtag.event('searched_members', {
          event_category: 'members',
          event_label: `${this.$route.params.group} / ${this.searchValue}`,
        });

        this.filterProfiles();
      }, 400);
    },
    handleIsAttendingFilter(filter) {
      if (filter) {
        this.profileInfo.filters.attending_next_event_include = 1;

        // Google Analytics Event
        this.$gtag.event('is_attending_filter', {
          event_category: 'members',
          event_label: `${this.$route.params.group} / Filtered by is_attending`,
        });
      } else {
        delete this.profileInfo.filters.attending_next_event_include;
      }
      this.fetchProfiles(this.profileInfo);
    },
    handleOnListFilter(filter) {
      if (filter) {
        this.profileInfo.filters.is_on_my_list_include = 1;

        // Google Analytics Event
        this.$gtag.event('on_list_filter', {
          event_category: 'members',
          event_label: `${this.$route.params.group} / Filtered by on_list`,
        });
      } else {
        delete this.profileInfo.filters.is_on_my_list_include;
      }
      this.fetchProfiles(this.profileInfo);
    },
    filterProfiles() {
      this.filteredProfiles = this.profiles.filter((profile) => {
        const fullName = `${profile.firstName.toLowerCase()} ${profile.lastName.toLowerCase()}`;
        const fullAddress = `${profile.city && profile.city.toLowerCase()}, ${
          profile.state && profile.state.toLowerCase()
        }`;
        const fullPrevName = `${
          profile.previousFirstName && profile.previousFirstName.toLowerCase()
        } ${profile.previousLastName && profile.previousLastName.toLowerCase()}`;

        return (
          fullName.indexOf(this.searchValue.toLowerCase()) >= 0 ||
          fullAddress.indexOf(this.searchValue.toLowerCase()) >= 0 ||
          fullPrevName.indexOf(this.searchValue.toLowerCase()) >= 0 ||
          (profile.bio && profile.bio.toLowerCase().indexOf(this.searchValue.toLowerCase()) >= 0) ||
          (profile.country &&
            profile.country.toLowerCase().indexOf(this.searchValue.toLowerCase()) >= 0)
        );
      });
    },

    searchEnterHit(val) {
      this.profilesEnterHit = val;
      if (val) {
        this.showFilterResults = false;
      } else {
        this.showFilterResults = true;
      }
    },
    searchFocused(isFocused) {
      if (isFocused) {
        this.searchEnterHit(false);
      }
    },
    checkAuthentication() {
      if (
        !this.$route.params.group &&
        this.user &&
        this.user.groups &&
        this.user.groups.length > 0
      ) {
        this.$router.replace({
          name: 'members',
          params: {
            group: this.user.groups[0].slug,
          },
        });
        this.checkAuthentication();
      } else if (!this.$route.params.group && !this.user) {
        this.$router.push({
          name: 'not-found',
        });
      }

      if (this.$route.params.group && !this.notAuthenticatedOrNotMember) {
        this.fetchProfileFields({ groupSlug: this.$route.params.group });
      }
    },
    handleJoinGroup() {
      if (!this.isAuthenticated) {
        this.$router.push({
          name: 'groupPreview',
          params: {
            group: this.$route.params.group,
            isFromMembers: true,
          },
        });
      } else if (this.isAuthenticated && !this.memberOfGroup) {
        this.$router.push({
          name: 'join-group',
          params: {
            group: this.$route.params.group,
          },
        });
      }
    },
    hideWelcomeModal() {
      document.body.style.overflow = 'auto';
      this.updateWelcomeModalOpen(false);
    },
    showModal() {
      // Google Analytics Event
      this.$gtag.event('clicked_invite', {
        event_category: 'members',
        event_label: `${this.$route.params.group} / Clicked invite button`,
      });
      this.updateModalOpen(true);
    },
    profileLinkClicked() {
      // Google Analytics Event
      this.$gtag.event('clicked_profile', {
        event_category: 'members',
        event_label: `${this.$route.params.group} / Clicked user profile card`,
      });
    },
    setOnYourList(profile) {
      if (this.group.slug && profile.slug) {
        const profileData = {
          groupSlug: this.group.slug,
          profileSlug: profile.slug,
        };
        this.loading = true;

        // Google Analytics Event
        this.$gtag.event('add_to_list', {
          event_category: 'engagement',
          event_label: `${this.$route.params.group} / User added another user to list`,
        });
        if (
          !this.cardProfilesShown.find((x) => {
            return x.slug === profile.slug;
          }).isOnMyList
        ) {
          this.addProfileToList(profileData).then((response) => {
            if (response.success) {
              profile.isOnMyList = true;
            }
            this.loading = false;
          });
        } else {
          this.removeProfileFromList(profileData).then((response) => {
            if (response.success) {
              profile.isOnMyList = false;
            }
            this.loading = false;
          });
        }
      }
    },
    deleteFundraiserDialog(index) {
      this.currentFundraiser = this.group.fundraisers[index];
      this.showRemoveFundraiserDialog = true;
    },
    cancelRemovingFundraiser() {
      this.currentFundraiser = {};
      this.closeRemovingFundraiserDialog();
    },
    removeFundraiser() {
      const index = this.group.fundraisers.indexOf(this.currentFundraiser);
      this.group.fundraisers.splice(index, 1);
      this.saveGroupChanges(this.group, this.group.slug);
      this.closeRemovingFundraiserDialog();
    },
    closeRemovingFundraiserDialog() {
      this.showRemoveFundraiserDialog = false;
    },
    getFundraiserDialogName() {
      return 'Delete fundraiser?';
    },
    getFundraiserDialogText() {
      return 'Are you sure you want to delete this fundraiser?';
    },
    seeAllEvents() {
      this.$router.push({
        name: 'events',
        params: {
          group: this.$route.params.group,
        },
      });
    },
    seeAllFundraisers() {
      this.$router.push({
        name: 'description',
        params: {
          group: this.$route.params.group,
        },
      });
    },
    printDiv() {
      window.print();
    },
    saveGroupChanges(group, groupSlug) {
      if (group && groupSlug) {
        const formattedGroup = {
          ...group,
        };
        this.showToast = false; // reset
        this.updateGroup({
          groupSlug,
          group: formattedGroup,
        }).then((response) => {
          if (response.success) {
            this.fetchGroup({ groupSlug });
            this.showToast = true;
            this.inEditMode = false;
            this.setCurrentGroup(response.group);
            this.scrollToTop();
          } else if (response.errors) {
            this.editErrors = response.errors;
          }
        });
      }
    },
  },
  watch: {
    user() {
      this.checkAuthentication();
    },
    profiles() {
      this.filterProfiles();
    },
    group() {
      if (!this.notAuthenticatedOrNotMember) {
        this.fetchEventList({ groupSlug: this.$route.params.group });
      }
    },
  },
};
</script>

<style lang="scss">
.member__icon-close {
  path {
    @apply stroke-gray-900;
  }
}
.member-card {
  box-shadow: 0 2px 4px rgba(17, 17, 17, 0.08);
  transition: max-height 0.1s cubic-bezier(0, 1, 0, 1);
  @apply w-full sm:w-227px h-150px max-h-150px sm:h-370px sm:max-h-370px flex sm:flex-col relative bg-white rounded;

  &.show-more {
    transition: max-height 0.1s ease-in-out;
    @apply h-auto max-h-full;
  }

  &:hover {
    box-shadow: 0 15px 24px rgba(17, 17, 17, 0.05);
  }

  .picture {
    left: 50%;
    transform: translateX(-50%);
    @apply w-auto h-full sm:h-227px min-w-150px absolute sm:relative;
  }
}

.circle {
  @apply rounded rounded-full bg-gray-100 w-16 h-16 items-center justify-center;
  background-color: #f6f6f6;
}

.matched-text {
  @apply text-gray-900;
}

.search-dropdown-container {
  @apply z-10 absolute rounded ring-1 ring-black ring-opacity-5 shadow-lg bg-white pr-2 mt-2 w-full;

  .search-dropdown {
    @apply overflow-y-auto max-h-240px p-6 w-full;
  }
}

@media print {
  .no-print {
    display: none;
  }
}
@media screen {
  .only-print {
    display: none;
  }
}
</style>
